.nav.side-menu > li {
  width: 100%;
}

.navbar {
  padding: 0;
}

.bottom .btn,
.btn-group .btn,
.modal-footer .btn,
button.btn {
  /* height: 32px; */
}

.btn-group .btn-secondary {
  height: 32px;
}

.bottom .btn-sm,
.btn-group .btn-sm,
.modal-footer .btn-sm,
button.btn-sm {
  height: 27px;
}

.fravaerPanel .btn {
  height: initial;
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.8rem;
}

.table-xs > :not(caption) > * > * {
  padding: 0.03rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  line-height: 1.3rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 1;
}
