.well {
  padding: 10px;
  margin-left: 0;
  background: #fff;

  /* border-radius: 3px; */

  /*	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3); */

  /*	border:1px solid lightgrey; */
  color: black;
}

.profile_details .profile_view > .bottom {
  display: none !important;
}

.journal_list .journal_entry > .bottom {
  display: none !important;
}

.table_list .table_entry > .bottom {
  display: none !important;
}

.table_list_input_rows .table_entry > .bottom {
  display: none !important;
}

.profile_details .profile_view:hover .bottom {
  display: block !important;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 2px 4px;
}

.journal_list {
  margin-top: 20px;
  padding-right: 5px;
  overflow-y: scroll;
  max-height: 600px;
}

@media print {
  .journal_list {
    overflow-y: visible;
  }
}

.journal_list .journal_entry:hover .bottom {
  display: block !important;
  position: absolute;
  left: 0;
  bottom: 0;

  /* width: 100%; */
  padding: 2px 4px;
}

.table_list .table_entry:hover .bottom {
  display: block !important;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 2px 4px;
}

.table_list .table_entry:hover .bottom_placeholder,
.payments_table .table_entry:hover .bottom_placeholder {
  display: none;
}

.table_list_input_rows .table_entry:hover .bottom {
  display: block !important;
  position: absolute;
  left: 0;
  bottom: 0;

  /* width: 100%; */
  padding: 1px 4px;
}

.table_list {
  margin-top: 10px;

  /* padding-right:5px; */
  overflow-y: scroll;
  max-height: 350px;
}

.table_list_input_rows {
  margin-top: 10px;

  /* padding-right:5px; */
  overflow-y: scroll;
  max-height: 350px;
}

@media print {
  .table_list {
    overflow-y: visible;
  }
}

.fravaersvurdering .well,
.massevurdering .well {
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 30%);
  border: 1px solid lightgrey;
}

.profile_details p {
  word-break: break-all;
}
