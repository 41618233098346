@media print {
  pre {
    white-space: pre-wrap;
  }

  .profile_details {
    width: 100%;
  }

  a[href]::after {
    content: initial;
  }

  .main_container .top_nav {
    margin: 0 !important;
  }

  .main_container .top_nav,
  .left_col {
    display: none !important;
  }

  .main_container {
    margin: 0;
  }
}

@media print {
  @page {
    size: a4; /* DIN A4 standard, Europe */

    /* size: landscape; */
    margin: 20mm; /* Small top and bottom margin removes browser inserted header and footer */
  }

  .d-print-caption {
    display: table-caption !important;
  }

  .text-white {
    color: black !important;
  }

  table {
    /* height: 99%; */
  }

  .keymessage {
    overflow: visible !important;
  }

  .print-table-bordered > :not(caption) > * > * {
    border-width: 1px;
  }

  table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: always;
  }

  table caption {
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
  }

  html,
  body {
    width: 210mm;
    min-height: auto;
    background: white !important;
    font-family: Arial, Helvetica, sans-serif !important;

    /* border: 1px solid rgba(0, 0, 0, 0) !important; */

    /* height: 99.9% !important; */
    page-break-after: auto;
    page-break-before: auto;
    contain: content;
    overflow: visible !important;
  }

  body:last-child {
    margin-bottom: 0;
  }

  footer {
    display: none;
  }

  #printContainer {
    width: 99% !important;
  }

  tr,
  tr:nth-of-type(odd) > *,
  tr:nth-of-type(even) > * {
    page-break-inside: avoid;
    page-break-after: avoid;
    background: transparent !important;
  }

  .no-printed-stripes table > tbody > tr:nth-child(odd) > td,
  .no-printed-stripes table > tbody > tr:nth-child(even) > td {
    background: transparent !important;
  }

  .bg-success {
    background: inherit !important;
  }

  th {
    break-inside: auto;
  }

  td {
    color: black !important;
    background: transparent !important;
    border-color: rgb(222 226 230) rgb(222 226 230) rgb(222 226 230)
      rgb(222 226 230) !important;
    border-width: 1;
  }

  thead {
    break-inside: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }

  .min-vh-100 {
    min-height: none !important;
  }
}

@media only print {
  * {
    /* overflow: visible !important; */
    page-break-inside: auto;
  }

  * {
    margin: 0;
    padding: 0;
  }

  .page {
    page-break-after: auto;
  }

  .modal {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: visible !important;
    margin: 0 !important;
  }

  .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }

  .modal-body {
    padding: 0 !important;
  }

  .modal-content {
    border: 0 !important;
  }

  .gradient-linear_left {
    display: none;
  }

  .inner_padding {
    padding: 0 !important;
  }

  .rapportSektion {
    width: 100vw;
    height: 100vh;
    page-break-inside: avoid;
    page-break-before: always;
    break-before: always;
  }

  .page:not(:last-child) {
    /*  break-after: always;
    page-break-after: always; */
  }

  .patientensaftaler:not(:last-child) {
    /*  break-after: always;
    page-break-after: always; */
  }
  .patientensaftaler 
  {
    height: 100% !important;
    overflow-y: visible !important;
    max-height: 100% !important;
  }
  .table_list
  {
    height: 100% !important;
    overflow-y: visible !important;
    max-height: 100% !important;
  }
  .patientens_aftaler_container
  {
    height: 100% !important;
    max-height: 100% !important;
    overflow-y: visible !important;
    max-height: 100% !important;
  }
  .bottom,
  .bottom > * {
    display: none;
  }

  .Filterpanel {
    display: none;
  }

  .Filterpanel > * {
    display: none;
  }

  .nav-sm .container.body .right_col {
    margin: 0;
    padding: 0 !important;
  }

  .profile_details .profile_view {
    width: 100%;
    padding-bottom: 10px;
    border: 0;
    padding: 0 !important;
  }

  .profile_details {
    padding-right: 0;
  }

  .profile_details .profile_view .left {
    min-height: initial;
  }

  .form-group .btn {
    margin-bottom: 0;
  }

  .panel_toolbox {
    display: none;
  }

  .cardsWide {
    border: 1px solid lightgray;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .rapportSektion > canvas.chartjs-render-monitor {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: auto !important;
  }

  #main {
    border-left: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    display: block;
  }

  nav.sidemenu,
  .x_title {
    display: none !important;
  }

  .x_panel {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  main {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .simplebox > .well {
    padding: 0;
  }

  .well {
    padding: 0;
  }

  #outerContainer {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  nav {
    display: none !important;
  }

  .navside {
    display: none !important;
  }

  .table_item_row .bg-success {
    background-color: white !important;
    color: initial !important;
  }

  #dagens_aftaler {
    overflow-y: visible !important;
    max-height: initial !important;
  }
}
