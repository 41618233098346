.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.no-cursor {
  cursor: none !important;
}

.normal-cursor {
  cursor: inherit;
}
