.loading {
  margin: 10px auto;
  font-size: 40px;
  width: 300px;
}

.dropdown-menu .loading {
  font-size: inherit;
  width: inherit;
}

.loading.btn {
  margin: 10px auto;
  font-size: inherit;
  width: inherit;
}
