.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
  opacity: 1 !important;
}

.btn {
  /* line-height: 16px; */
}

.view_switcher label.btn {
  line-height: 20px;
}

.btn:disabled {
  cursor: none !important;
}
