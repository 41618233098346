@font-face {
  font-family: AtkinsonHyperlegible;
  src:
    url("/fonts/Atkinson-Hyperlegible-Regular-102.woff2") format("woff2"),
    url("/fonts/Atkinson-Hyperlegible-Regular-102.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: AtkinsonHyperlegible;
  src:
    url("/fonts/Atkinson-Hyperlegible-Bold-102.woff2") format("woff2"),
    url("/fonts/Atkinson-Hyperlegible-Bold-102.woff") format("woff");
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: AtkinsonHyperlegible;
  src:
    url("/fonts/Atkinson-Hyperlegible-Italic-102.woff2") format("woff2"),
    url("/fonts/Atkinson-Hyperlegible-Italic-102.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}
