.forminput {
  width: 100%;
}

.forminput-searchclear {
  position: absolute;
  right: 7px;
  top: 4px;
  bottom: 0;
  margin: auto;
  font-size: 22px;
  cursor: pointer;
  color: #ccc;
}

.fullWidth {
  width: 100%;
}

.userName,
.userName input {
  text-transform: uppercase;
}

.form-label {
  margin-bottom: 0;
}
