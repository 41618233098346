.x_panel {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 17px;
  display: inline-block;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  transition: all 0.2s ease;
}

.x_title {
  border-bottom: 2px solid #e6e9ed;
  padding: 1px 5px 6px;
  margin-bottom: 10px;
}

.x_title .filter {
  width: 40%;
  float: right;
}

.x_title h2 {
  margin: 5px 0 6px;
  float: left;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.x_title h2 small {
  margin-left: 10px;
}

.x_title span {
  color: #bdbdbd;
}

.x_content {
  padding: 0 5px 6px;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin-top: 5px;
}

.x_content h4 {
  font-size: 16px;
  font-weight: 500;
}

/*
.x_content {
    direction: rtl;
}
*/
.topbox .x_content {
  min-height: 330px;
}

.x_content .row {
  margin-right: 0;
  padding-right: 0;
  margin-left: 0;
  padding-left: 0;
}

.x_content {
  padding: 0;
}

.x_panel {
  padding-left: 10px;
  padding-right: 10px;
}

.x_panel {
  page-break-inside: avoid;
}
