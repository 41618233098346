$chart-frame-height: 500px;
$chart-frame-width: 700px;

.chart-container {
  position: relative;
  width: 100%;
  height: 500px;

  &.scroll-y {
    overflow: hidden scroll;
  }

  &.scroll-x {
    overflow-x: scroll;
  }

  .resizable {
    height: $chart-frame-height;
    width: $chart-frame-width;
  }

  .chart-frame {
    position: absolute;
    inset: 0;
    background-color: white;
    overflow: hidden auto;
  }
}
