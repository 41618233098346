.lukkeperiode {
    background-color: #2199e8;
    position: relative;
  }
  
  .lukkeperiode > div {
    display:none;
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 10100;
    background-color: #fff;
    border: 1px solid #ccc;
    color: #222;
    min-width: 120px;
    padding: 5px;
  }
  
  .lukkeperiode:hover > div {
    display: block;
  }

  