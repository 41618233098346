.artikel {
}

.artikel img {
  border: 1px solid whitesmoke;
  display: block;
  margin: 70px auto;
}

.artikel h3 {
}

.artikel-liste-sm > li {
  padding: 0.5rem;
  border: 0;
  border-right: 0;
}
