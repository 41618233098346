.filterbox {
  max-height: 50px;
  height: 50px;
  transition: max-height 0.5s ease-out;

  /* height: 140px; */
}

.filterbox.maximized {
  max-height: 500px;
  height: auto;
  transition: max-height 0.5s ease-in;
}

.Filterpanel {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Filterpanel > div {
  margin-bottom: 4px !important;
}

.tile {
  overflow: hidden;
}

.ember-basic-dropdown,
.ember-power-select-placeholder {
  font-size: 15px;
}
