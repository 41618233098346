.legend {
  padding: 10px;
}

.legendValue {
  border: 1px solid gray;
  padding-left: 5px;
  width: 30px;
}

.legend .candidate {
  background-color: blue;
}

.legend .company {
  background-color: #95d8dd;
}

.legend .jobcenter {
  background-color: #cee062;
}
