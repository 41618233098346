.yeti-table-pagination-controls {
  display: flex;
  justify-content: space-between;
}

.yeti-table-pagination-controls-page-size {
  margin-left: auto;
}

.yeti-table-pagination-controls-previous {
  margin-left: auto;
}

.product_table > thead > tr:first-child,
.product_table > tbody tr > td:first-child {
  white-space: nowrap;
}

.surface_table > thead > tr:first-child,
.surface_table > tbody tr > td:first-child {
  white-space: nowrap;
}

.order_table > thead > tr:first-child,
.order_table > tbody tr > td:first-child {
  white-space: nowrap;
}

.surface-header-width {
  min-width: 140px;
}

.time_column {
  min-width: 120px;
}

.table_item_row {
  cursor: inherit;
}
