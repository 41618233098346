.datepicker > .input-button {
  position: absolute;
  right: 5px;
  top: 8px;
  cursor: pointer;
  border: 0;
}

.datepicker > input {
  padding-left: 7px;
}

.dayContainer > span:nth-child(7n-1),
.dayContainer > span:nth-child(7n) {
  color: rgb(72 72 72 / 30%);
}

.ember-flatpickr-input {
  padding-left: 5px !important;
}

.flatpickr-disabled {
  color: rgb(220 53 69) !important;
}
