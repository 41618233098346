

:root {
  --main-bg-color: #4d559b; /* #0078D4; */
  --hover-bg-color: #232c39; /* #235A9F; */
  --active-bg-color: #232c39; /* #174276; */
  --main-text-color: #fff;
}

body {
  margin: 0;

  /* Reset default margin */
  color: #212529; /* #73879c; */
  background: #2a3f54;
  font-family:
    AtkinsonHyperlegible,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    "Droid Sans",
    Arial,
    sans-serif;

  /* Adjust font size */
  text-size-adjust: 100%;

  /* Font varient */
  font-variant-ligatures: none;

  /* Smoothing */
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.471;
  overflow-y: scroll;
  min-height: 100vh;
}

.text-primary {
  color: var(--main-bg-color) !important;
}

pre {
  background-color: transparent;
  border-width: 0;
  box-sizing: border-box;
  color: inherit;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  white-space: pre-line;
  padding: inherit;
  word-break: inherit;
  word-wrap: inherit;
}

.topIndex {
  z-index: 10000 !important;
}

.highlight {
  padding: 9px 14px;
  margin: 0 0 14px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 4px;
  border-width: 1px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.modalTextarea textarea {
  height: 120px;
}

.stortTextarea textarea {
  height: 200px;
}

.contentSection {
  min-height: 3732px;
  padding: 0;
}

.checkboxFlyttetnedad {
  margin-top: 30px;
}

.nav_menu {
  margin-bottom: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.menu_section .fa {
  width: 18px;
}

.count-sm {
  font-size: 25px;
}

.pageContent a:link,
.pageContent a:hover,
.pageContent a:visited,
.pageContent a:active {
  text-decoration: none;
}

.isLink {
  cursor: pointer;
}

.tile-stats.selected {
  background-color: whitesmoke;
}

.boxToolbar {
  text-align: right;
  padding: 0;
  position: absolute;
  top: 3px;
  right: 7px;
  z-index: 2;
  opacity: 0.9;
  background-color: inherit;
  display: none;
}

.tile-stats:hover .boxToolbar {
  display: block;
}

.is-selected {
  border: 1px solid yellow;
}

#sidetitel {
  font-size: 25px;
}

a {
  color: black;
  text-decoration: none;
}

h2 {
  font-size: 18px;
  font-weight: 400;
}

.subpagePanel {
  display: block; /* iframes are inline by default */
  background: #000;
  border: none; /* Reset default border */
  height: 100vh; /* Viewport-relative units */
  width: 100%;
  background-color: transparent;
}

.loading-message {
  text-align: center;
  color: #aaa;
}

.error-pane {
  margin: 25px auto;
  background-color: rgb(255 176 176 / 33%);
}

.error-message {
  text-align: center;
  color: #e22626;
  line-height: 100px;
}

.loading-pane {
  margin: 120px auto;
}

.loading-message {
  text-align: center;
  color: #aaa;
}

.btn.fravaerBar {
  margin-bottom: 5px;
  margin-right: 5px;
}

.modal-body .btn-primary {
  display: none;
}

.versioninfo {
  float: right;
  width: 250px;
  margin-top: 5px;
  margin-left: 0;
}

.loginTitle {
  font-size: 30px;
  color: white;
  width: 200px;
}

.tile-stats {
  min-height: 75px;
}

.tile-stats h3 {
  padding-top: 0;
  margin-top: 0;
  top: 12px;
  margin-right: 65px;
  white-space: normal;
  overflow-wrap: break-word;
}

.tile_count .tile_stats_count,
.tile_count .tile_stats_count::before {
  border-bottom: 0;
  border-left: 0;
}

.no-select {
  -webkit-touch-callout: none;
  user-select: none;
}

.g-map-canvas {
  /* width: 600px; */
  height: 400px;
}

.submitButton {
  margin-top: 5px;
  margin-right: 0;
  float: right;
}

.x_title .nav > li > a {
  padding: 5px;
}

.x_title h2 {
  overflow-y: display;
}

.ember-power-select-trigger {
  padding: 3px 16px 3px 0;
}

.ember-power-select-clear-btn {
  padding-left: 10px;
  padding-right: 3px;
}

.tab-content {
  height: 480px;
}

@keyframes drop-fade-below {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes drop-fade-above {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.toolboxlist {
  list-style-type: none;
  padding-right: 0;
  padding-left: 0;
  //padding-left: 0;
}

.toolboxlist li {
  padding-top: 8px;
}

.toolboxlist li .fa {
  padding-right: 5px;
}

.toolboxTopBox {
  padding-left: 30px;
  padding-right: 0;
}

.height70 {
  height: 70px;
}

.height350 {
  min-height: 350px;
}

.wide.popover {
  width: 400px;
}

textarea[name="internalAuthorityCommentField"] {
  height: 100px;
}

.form-control {
  font-size: 15px;
  height: 38px;
}

.loading-slider {
  top: 165px;
}

.toolButton {
  display: block;
  padding: 9px;
}

.nomargin {
  margin: 0;
}

.glemtPasswordLink {
  cursor: pointer;
  font-size: 20px;
  line-height: 40px;
}

.smallcaps {
  font-variant: all-petite-caps;
  margin: 0;
}

.humanize::first-letter {
  text-transform: uppercase;
}

.mediumHeightBox {
  min-height: 300px;
  height: 300px;
}

.smallHeightBox {
  min-height: 140px;
  height: 140px;
}

.largeHeightBox {
  min-height: 450px;
  height: 450px;
}

.profile_details .profile_view .left {
  margin-top: 10px;
  padding-right: 0;
  min-height: 100px;
}

.inlined {
  display: inline;
}

.outlet_container {
  position: relative;
  overflow: hidden;
  transform: translateY(0);
  min-height: 1150px;
}

.profile_details .profile_view .bottom {
  line-height: 28px;
  background: #f2f5f7;
  padding: 9px 0;
  border-top: 1px solid #e6e9ed;
}

.journal_list .journal_entry .bottom {
  line-height: 28px;
  background: #f2f5f7;
  padding: 9px 0;
  border-top: 1px solid #e6e9ed;
}

.small_appointment_list {
  overflow: hidden auto;
  min-height: 350px;
}

.table_list .table_entry .bottom {
  line-height: 28px;
  background: #f2f5f7;
  padding: 9px 0;
  border-top: 1px solid #e6e9ed;
}

.table_list_input_rows .table_entry .bottom {
  line-height: 28px;
  background: #f2f5f7;
  padding: 9px 0;
  border-top: 1px solid #e6e9ed;
  height: 34px;
  top: 5px;
}

address {
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 1.1;
}

h4.brief {
  font-size: 16px;
  font-variant: all-petite-caps;
}

.profile_details {
  padding-left: 0;
  page-break-inside: avoid;
}

.profile_details .profile_view .left,
.profile_details .profile_view .right {
  margin-top: 0;
}

.profile_details .profile_view .right {
  margin: 0;
  padding: 0;
}

.kontaktInput {
  height: 49px;
}

.ingenPadding {
  padding: 0;
}

#fravaerVurderingAktivitetNavn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (width <= 767px) {
  .billedSektion {
    display: none;
  }
}

.boxContainer {
  white-space: normal;
  word-break: break-all;
}

address {
  overflow: hidden;
  word-wrap: break-word;
  margin-top: 0;
}

.modal-content .profile_details .profile_view {
  min-height: 240px;
}

.btn-xs {
  margin-right: 1px;
}

.nav-md .container.body .right_col {
  padding: 10px 0 0 10px;
  margin-left: 230px;
}

.profile_details .profile_view .brief {
  font-variant: all-petite-caps;
}

.contentSection,
.contentSection a,
.contentSection a:link,
.contentSection a:visited,
.contentSection a:hover,
.contentSection a:focus {
  color: black;
}

.contentSection a.btn,
.contentSection a.btn:link,
.contentSection a.btn:visited,
.contentSection a.btn:hover,
.contentSection a.btn:focus {
  color: white;
}

.minheight200 {
  min-height: 200px;
}

.modal-content .fravaerInfo .profile_details .profile_view {
  min-height: 180px;
  height: 180px;
}

.modal-open {
  overflow-y: auto;
}

.modal-content .fravaerPanel {
  padding: 0;
  padding-top: 5px;
  margin: 0;
}

.modal-title {
  color: black;
}

.x_title span {
  color: black !important;
}

.toggle {
  padding-top: 0;
  width: 50px;
}

.toggle a {
  padding-top: 10px;
}

.card-container {
  display: flex;
  flex-flow: row wrap;
}

.nav.side-menu > li.active > a {
  transition: all 0.3s ease-out;
}

.ember-power-select-selected-item {
  display: block;
  max-height: 100px;
}

.table-ugeplan td {
  /* padding: 0.4rem; */
  line-height: 2rem;
}

.table-ugeplan td .btn-group input {
  width: 100px;
}

.ember-power-select-clear-btn {
  top: 0;
}

.gradient-linear_left {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(to right, lightgray, #ededed);
}

.side-menu {
  max-width: 230px;
}

.nav.side-menu > li > a {
  margin-bottom: 0;
}

.nav-sm .nav.side-menu li a svg {
  width: 100% !important;
  margin-bottom: 5px;
  text-align: center;
  font-size: 25px !important;
}

.dropdown {
  line-height: 1.2em;
  padding-top: 0.3em;
}

.dropdown .dropdown-menu {
  padding: 0.3rem;
}

.site_title {
  line-height: 40px;
}

ul.nav.side-menu > li > a > svg {
  width: 20px;
}

.nav-sm .container.body .right_col {
  padding: 10px;
}

ul.nav.side-menu > li > a {
  /* word-break: break-all; */
  white-space: normal;
}

ul.nav-sm.side-menu > li > a {
  word-break: break-all;
}

.nav-sm .container.body .right_col {
  padding: 10px;
}

.nav-sm .navbar.nav_title {
  text-align: center;
}

.nav-sm .navbar.nav_title > .site_title {
  padding: 0;
}

.nav_title {
  background-color: #ededed;
  height: 39px;
}

.cardsWide {
  width: 600px;
}

.cardsNarrow {
  width: 300px;
  padding-right: 10px;
}

.cardsNormal,
.cardsNarrow,
.cardsWide {
  width: 450px;
}

.cardsNormal,
.cardsNarrow,
.cardsWide {
  border-radius: 3px;
  border: 1px solid lightgrey;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 30%);
  margin-right: 5px;
}

.autocomplete_item {
  padding: 5px 2px;
  line-height: 1em;
}

.autocomplete_item_header {
  font-weight: bold;
}

.autocomplete_item_body {
  font-weight: normal;

  /* font-family: monospace; */
  font-size: normal;
}

.input-daterange input,
.daterange,
.nummerVisning {
  font-variant-numeric: tabular-nums;
}

.profile_details .profile_view {
  padding-top: 5px;
  display: inline-block;
  padding: 10px 0 0;
  background: #fff;
}

.bottom > .btn {
  margin: 2px 0;
}

.nummerVisning input {
  font-variant-numeric: tabular-nums;
}

.nummerVisning.badge.badge-light {
  border: 1px solid whitesmoke;
  font-weight: 400;
  height: 1.25rem;
}

.dataheader {
  font-variant-caps: small-caps;
  text-transform: lowercase;
  font-weight: 500;
}

.form-group label,
legend {
  display: inline-block;
  font-variant: small-caps;
  text-transform: lowercase;
  margin-bottom: 0;
}

legend {
  font-size: 17px;
}

h2.lead {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", "Droid Sans", Arial,
    sans-serif !important; */

  /* Smoothing */
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
  margin-bottom: 0.1em;
}

.nav-sm .nav.side-menu li a svg {
  margin-bottom: 0;
}

.nav-sm .nav.side-menu li a {
  padding: 5px;
}

.left_col {
  background-color: #0078d4 !important; /* #2A3F54; */
  color: black;
}

.nav.side-menu > li > a,
.nav.child_menu > li > a {
  color: rgb(108 117 125);
}

.nav.side-menu > li.active {
  //border-bottom: 1px solid #ededed;
}

.nav.side-menu > li.active > a {
  background: #545b62;
  border-color: #4e555b;
  opacity: 1;
  color: white;
}

.nav.side-menu > li.active > a {
  box-shadow: none !important;
  text-shadow: none !important;
}

.nav.side-menu > li > a:hover {
  background: rgb(108 117 125);
  color: white;
}

body {
  background-color: #ededed;
}

h1.IngenElementerBesked {
  margin-top: 2em;
  padding-bottom: 2em;
}

.fejlBesked {
  padding: 2em;
}

.FixedButtonHeight {
  height: 34px;
}

.x_title h2 svg {
  width: 20px !important;
}

.bottom .btn-sm {
  height: 25px;
}

.btn-primary {
  background-color: var(--main-bg-color);
}

.btn-primary:hover {
  background-color: var(--hover-bg-color);
}

.btn-danger {
  background-color: #e35d6a; /* #c70039; */
  border-color: #e35d6a; /* #c70039; */
}

.btn-secondary {
  opacity: 0.65 !important;
}

.btn-secondary.active {
  opacity: 1 !important;
}

.narrow {
  line-height: 1.2em;
}

.bd-callout + .bd-callout {
  margin-top: -0.25rem;
}

.bd-callout-warning {
  border-left-color: #f0ad4e !important;
}

.bd-callout {
  color: black;
  padding: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem !important;

  /*  border-radius: .25rem; */
}

.bottom > a.btn {
  display: inline-block;
  max-width: 100%; /* 34px; */
  overflow: hidden;
  white-space: nowrap;
}

.bottom > a.btn > .buttontitle,
.artikel a.btn > .buttontitle {
  display: inline-block;
}

.bottom > button.btn > .buttontitle,
.artikel button.btn > .buttontitle {
  display: inline-block;
}

.bottom > a.btn:hover,
.artikel a.btn:hover {
  max-width: 100%;
}

.bottom > button.btn:hover,
.artikel button.btn:hover {
  max-width: 100%;
}

.bottom > a.btn:hover > .buttontitle,
.artikel a.btn:hover > .buttontitle {
  display: inline-block;
}

.bottom > button.btn:hover > .buttontitle,
.artikel button.btn:hover > .buttontitle {
  display: inline-block;
}

.bottom > a.btn-danger {
  max-width: 100%;
}

.bottom > button.btn-danger {
  max-width: 100%;
}

.bottom > a.btn-danger > .buttontitle {
  display: inline-block;
}

.bottom > button.btn-danger > .buttontitle {
  display: inline-block;
}

.bottom > a.btn,
.artikel > a.btn {
  transition:
    max-width 2s linear,
    background-color 0.2s ease-in-out;
}

.bottom > button.btn,
.artikel > button.btn {
  transition:
    max-width 2s linear,
    background-color 0.2s ease-in-out;
}

.modal-footer {
  z-index: 2;
}

#main {
  min-height: 100vh;
  margin-left: 0;
  border-left: 70px solid var(--main-bg-color);
}

.artikel-liste > li > p {
  margin: 0;
  line-height: 1.3rem;
}

.inner_padding {
  /* padding:  .5rem; */
}

.markdown-editor-textarea {
  min-height: 300px !important;
  min-width: 500px !important;
}

.markdown-editor {
  min-height: 300px !important;
  min-width: 500px !important;
}

.modal-content {
  min-width: 799px !important;
}

.modal-sm .modal-content {
  min-width: 300px !important;
}

.time_input {
  width: 70px;
  font-variant-numeric: tabular-nums;
}

.form_appointment_edit .ember-flatpickr-input {
  display: none;
}

.form_appointment_edit .ember-basic-dropdown,
.form_patient .ember-basic-dropdown,
.form_payment_edit .ember-basic-dropdown,
.form_journal .ember-basic-dropdown {
  flex-grow: 1;
}

.slet_modal .modal-footer .btn-primary {
  border: 0;
  opacity: 1;

  --bs-bg-opacity: 1;

  background-color: rgb(var(--bs-danger-rgb) var(--bs-bg-opacity)) !important;
}

.input-group .btn {
  height: inherit !important;
}

.input-group-text {
  width: 45px;
}

.form_appointment_edit .flatpickr-calendar,
.filterbox .flatpickr-calendar,
.form_insurancecase .flatpickr-calendar {
  box-shadow: none !important;
}

.badge {
  user-select: all;
}

.form_insurancecase .ember-flatpickr-input {
  display: none;
}

.btn-group .btn {
  line-height: 20px;
}

@media (width >= 1700px) {
  .modal-xl {
    max-width: 1640px;
  }
}

.ember-basic-dropdown {
  flex-grow: 1;
}

.nobreakever {
  white-space: nowrap !important;
}

.in-modal-dropdown {
  z-index: 10210 !important;
}

.fs-0 {
  font-size: 40px !important;
}

.filter_dag {
  height: auto !important;
  max-height: 560px !important;
}

table {
  border-collapse: separate;
  border-spacing: 1px;
}

td > address {
  margin-bottom: 0 !important;
}

.table_item_row {
  vertical-align: middle;
}

.flatpickr-calendar {
  font-size: 18px;
}

.flatpickr-day {
  font-variant-numeric: tabular-nums;
}

.flatpickr-day.selected {
}

.flatpickr-day.today {
  background: gray;
  border-color: gray;
  color: white;
}
.flatpickr-day.today.selected {
  background: #569ff7;
  border-color: #569ff7;
  color: white;
}

.kabinestyring .form-control {
  height: initial !important;
}

.kabinestyring .form-control-sm {
  min-height: initial !important;
}

.kabinestyring td {
  padding: 0;
}

.kabinestyring .ember-power-select-trigger {
  line-height: 1.2em !important;
  min-height: 1.2em !important;
  height: 1.2em !important;
  padding: 0;
}

.kabinestyring .form-control {
  line-height: 1.2em !important;
  min-height: 1.2em !important;
  height: 1.2em !important;
}

.kabinestyring .autocomplete_item {
  padding: 0 !important;
}

.kabinestyring .form-control,
.kabinestyring button.btn-sm {
  font-size: 15px;
}

.kabinestyring button.btn-sm {
  height: 15px !important;
  font-size: 11px;
  padding: 0.1rem;
  padding-bottom: 0.2rem;
  line-height: 1;
}

search input {
  font-size: 17px !important;
  font-weight: bold !important;
}

.minimal_height_textarea textarea.form-control {
  min-height: 11px !important;
  height: 11px;
}

.kabinestyring .table-sm > :not(caption) > * > * {
  padding: 0 !important;
}

.ember-power-select-selected-item address,
.autocomplete_item_body address {
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 5px;
}
