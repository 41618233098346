nav.navside {
}

nav.navside > a {
  padding: 5px 5px 2px;
  color: var(--main-text-color);
  font-size: 10px;
  line-height: 15px;
  white-space: nowrap;
  text-align: center;
  min-width: 80px;
  background-color: var(--main-bg-color); /* #0078D4; */
  margin: 3px;
}

nav.navside > a > svg {
  color: var(--main-text-color);
  font-size: 25px !important;
  line-height: 37px;
}

nav.navside > a.active {
  background-color: var(--active-bg-color); /* #174276; */
}

nav.navside > a:hover {
  background-color: var(--hover-bg-color); /* #235A9F; */
  text-decoration: none;
}
