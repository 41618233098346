.artikel-liste > li {
  background: white;
}

.artikel-liste > li:nth-child(odd) {
  background: white;
}

.artikel-liste > li {
  list-style-type: none;
  padding: 1rem;
}

.artikel-liste > li:hover {
  background-color: whitesmoke;
}

.artikel-liste {
  padding-inline-start: 0;

  /* border-right: 1px solid whitesmoke; */
}

.artikel-liste > li > p {
  margin: 0;
}
