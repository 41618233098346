.popover-title,
.popover-content {
  color: rgb(128 128 128);
}

.popover {
  width: 300px;
  max-width: 300px;
}

.ikoner .popover,
#valgtVirksomhedsIkoner .popover {
  width: 700px;
  max-width: 700px;
}

#valgtVirksomhedsIkoner > img.tomtbillede {
  display: none;
}

.ui-list.item:hover {
  background-color: whitesmoke;
}

.ui-list.item .center-pane {
  align-items: left;
}

.ui-list.list-container .item {
  margin: 0;
}

.emptySearchResultBox {
  padding-left: 12px;
}

.listTekst {
  font-weight: 300;
}

.overskriftUdenMargin {
  margin-top: 0;
  margin-bottom: 0;
}

.pagination-links {
  font-size: 25px;
}
