.chart-pie svg {
  padding: 0;
  margin: 0;
  height: auto;
  width: 100%;
}

.chart-pie text {
  display: none;
}
