.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 1rem !important;
}

.pr-10 {
  padding-right: 2rem !important;
}

.pr-15 {
  padding-right: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 1rem !important;
}

.pl-10 {
  padding-left: 2rem !important;
}

.pl-15 {
  padding-left: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 1rem !important;
}

.pt-10 {
  padding-top: 2rem !important;
}

.pt-15 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 1rem !important;
}

.pb-10 {
  padding-bottom: 2rem !important;
}

.pb-15 {
  padding-bottom: 3rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 1rem !important;
}

.mr-10 {
  margin-right: 2rem !important;
}

.mr-15 {
  margin-right: 3rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 1rem !important;
}

.ml-10 {
  margin-left: 2rem !important;
}

.ml-15 {
  margin-left: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 1rem !important;
}

.mt-10 {
  margin-top: 2rem !important;
}

.mt-15 {
  margin-top: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 1rem !important;
}

.mb-10 {
  margin-bottom: 2rem !important;
}

.mb-15 {
  margin-bottom: 3rem !important;
}

.fullWidth {
  width: 100%;
}
