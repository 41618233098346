.nulstilPanel {
  position: fixed;
  inset: 0;
  opacity: 0.9;
  z-index: 9999;
}

.centerElement {
  margin-top: 200px;
  max-width: 400px;
}

.storTekst {
  font-size: 18px;
  line-height: 28px;
}

.closeIcon {
  font-size: 26px;
  float: right;
}

nav.loginsidemenu a {
  color: #fff;
  font-size: 10px;
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

nav.loginsidemenu a:hover {
  background-color: var(--hover-bg-color);
}

nav.loginsidemenu a.active {
  background-color: var(--active-bg-color);
}

nav.loginsidemenu {
  width: 70px;
  background-color: var(--main-bg-color);
}

nav.loginsidemenu a > svg {
  color: #fff;
  font-size: 25px !important;
  line-height: 37px;
}

nav.loginsidemenu a {
  padding: 5px;
  text-align: center;
}
