nav.sidemenu {
  width: 70px;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

nav.sidemenu > a {
  padding: 5px;
  color: white;
  font-size: 10px;
  line-height: 15px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}

nav.sidemenu > a > svg {
  color: white;
  font-size: 25px !important;
  line-height: 37px;
}

nav.sidemenu > a.active {
  background-color: var(--active-bg-color);
}

nav.sidemenu > a:hover {
  background-color: var(--hover-bg-color);
  text-decoration: none;
}

nav.sidemenu > a > span.navtitle {
  visibility: hidden;
}

nav.sidemenu > a.active > span.navtitle {
  visibility: visible;
}

nav.sidemenu > a:hover > span.navtitle {
  visibility: visible;
}
